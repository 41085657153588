import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import Badge from "../../components/_ui/Badge.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Karen’s Spring Blog - Edible Hanging Baskets`}</h1>
    <span className="text-metadata">Posted on 03/15</span>
    <Badge authorName="Karen Scott" authorPosition="Thistle Design" authorAvatar="/img/karen-avatar.jpg" mdxType="Badge" />
    <p>{`Hi Everyone,`}</p>
    <p>{`Something different for this spring - Plant edible hanging baskets for your pergola!`}</p>
    <p><strong parentName="p">{`Great accompaniment for your '5-in-1' BBQ Cook-Out System - see our Accessories' page for further details.`}</strong></p>
    <p>{`Hanging baskets make great use of space and are a fantastic way of stopping slugs and snails damaging the plants.`}</p>
    <p>{`Place in an open position with good light but with some shelter also. This is where your pergola becomes even more useful!`}</p>
    <p>{`Feed and water the basket regularly.`}</p>
    <h4>{`Planting Recipe:`}</h4>
    <ul>
      <li parentName="ul">{`35cm diameter wire framed hanging basket`}</li>
      <li parentName="ul">{`1 Basket liner`}</li>
      <li parentName="ul">{`Multipurpose compost`}</li>
      <li parentName="ul">{`Water retaining gel crystals`}</li>
      <li parentName="ul">{`Selection of small basil, parsley and coriander plants`}</li>
      <li parentName="ul">{`1 trailing bush tomato`}</li>
    </ul>
    <h4>{`Method:`}</h4>
    <p>{`Place the liner in the basket tucking in tightly. Then add a little multipurpose compost mixed with the water retaining crystals.`}</p>
    <p>{`Push some of the small herbs through from the outside of the basket so that the root balls can be covered over with compost on the inside.`}</p>
    <p>{`Plant the centre of the basket with more of the herbs and the trailing tomato plant.`}</p>
    <p>{`Water daily and feed once a week with diluted tomato feed.`}</p>
    <p>{`Then hang the basket on one of the protruding rafters of your `}<strong parentName="p">{`Perfect Pergola!`}</strong></p>
    <p>{`...And for dessert - how about doing the same process as above with a strawberry basket?`}</p>
    <p>{`Select early, mid and late cropping varieties for a continuous supply throughout the summer!`}</p>
    <p>{`Enjoy!`}</p>
    <p>{`Karen x`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      